import { PROGRESS_STATUS_OPTIONS } from "../../../../../../../constants";
import I18n from "../../../../../../localization/I18n";
import { ragStatusTextMatcher } from "../../../../../filters/TextMatcher";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../../columns/baseColumn/BaseColumn_config";
import { ISdColDef, ISdFilterDef } from "../../../columns/baseColumn/BaseColumnTypes";

export const RAG_STATUS_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "ragStatus",
  headerName: "ragStatus",
  pinned: "left",
  ...columnOptions
});

export const RAG_STATUS_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG,
  filter: "agTextColumnFilter",
  filterParams: {
    textMatcher: ragStatusTextMatcher
  }
};

export const mapProgressStatuses = () => {
  const progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
    return {
      label: I18n.t(e.label),
      key: e.key + ""
    } as FP.Generic.IKeyLabel;
  });

  return progressStatuses;
};
